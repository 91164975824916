


.topbar {
  background: #01215d;
  padding: 10px 0;

  @include below(phablet) {
    padding: 0; }

  .inner-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .left {
    @include below(phablet) {
      width: 100%; } }

  .contatos {
    @include below(phablet) {
      display: flex;
      justify-content: center; }
    a {
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0.38px;
      color: $colorTextWhite;
      padding: 10px 0;
      display: inline-block;

      @include below(phablet) {
        font-size: 14px;
        letter-spacing: 0; }

      &:nth-child(2) {
        margin: 0 10px;
        padding: 10px 15px;
        border-left: 1px solid #163570;
        border-right: 1px solid #163570; }

      @include below(phablet) {
        &:nth-child(2) {
          border-left: 1px solid #163570;
          border-right: none;
          padding: 10px 0 10px 15px;
          margin: 0 0 0 10px; }

        &:nth-child(3) {
          display: none; } } } } }

.buttons-login {
  display: flex;
  @include below(desktop) {
    display: none; }
  a {
    width: 136px;
    height: 36px;
    border-radius: 3px;
    border: 1px solid #5572a8;
    @include flexCenter;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.3px;
    color: $colorTextWhite;
    transition: all 0.3s ease;

    &:hover {
      background: $colorTextWhite;
      color: $primary; }

    + {
      a {
        margin-left: 10px; } }

    i {
      margin-right: 10px;
      font-size: 14px; } } }


header {
  &.fixedTop {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    background: #fff;
    border-bottom: 1px solid #01215d; }

  &.fixedHeader {
    border-bottom: 1px solid #01215d; }

  .header {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include below(phablet) {
      padding: 15px 0; } }

  .logo {
    @include below(desktop) {
      width: 90px; } }

  .right {
    display: flex;
    align-items: center;

    .button-whats {
      margin-left: 40px;
      @include below(large-desktop) {
        margin-left: 10px; }

      @include below(desktop) {
        display: none; }

      a {
        @include flexCenter;
        padding: 0 12px;
        height: 41px;
        border-radius: 8px;
        background-color: #25d366;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        i {
          font-size: 22px;
          margin-right: 10px;
          font-weight: normal; } } } } }

.menu-desktop {
  @include below(desktop) {
    display: none; }
  .menu-menu-1-container {
    ul {
      display: flex;
      li {
        a {
          font-size: 14px;
          font-weight: 500;
          color: $secondary;
          letter-spacing: 0.56px;
          text-transform: uppercase;
          margin-left: 25px;
          position: relative;
          transition: all 0.3s ease;

          &::after {
            content: '';
            width: 0;
            position: absolute;
            height: 3px;
            background: #0058a4;
            bottom: -5px;
            left: 50%;
            border-radius: 5px;
            transform: translateX(-50%);
            transition: all 0.3s ease; }
          &:hover {
            color: #0058a4;

            &::after {
              width: 50%; } }

          @include below(large-desktop) {
            margin-left: 15px; } }


        &.current-menu-item {
          a {
            color: #0058a4;

            &::after {
              width: 50%; } } } } } } }

.nav-mobile {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  top: 143px;
  left: 0;

  @include below(phablet) {
    top: 112px; }

  &.active {
    top: 85px;

    @include below(phablet) {
      top: 75px; } }

  &.is-open {
    opacity: 1;
    visibility: visible; }

  .button-nav {
    position: absolute;
    right: 40px;
    top: 32px;
    z-index: 9999;

    .btn-burguer .icon-bar {
      background: #f4a032 !important; }

    .btn-burguer.active .icon-bar:nth-of-type(2) {
      background: transparent !important; } }

  .buttons-login {
    display: flex !important;
    justify-content: center;
    a {
      background: #01215d; } }

  nav {
    padding: 0px 50px 20px 50px; }

  ul {
    margin: 0 auto;
    display: table;
    width: 100%;
    text-align: center;
    li {
      a {
        color: #01215d;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 3.2px;
        padding: 25px 0;
        text-transform: uppercase;
        text-align: center;
        display: block;
        border-top: 1px solid #01215d; }


      &:nth-child(1) {
        a {
          border-top: none; } }

      &.navDonate {
        display: table;
        margin: 0 auto;
        a {
          border-top: none;
          width: 120px;
          background: #000;
          color: #000;
          padding: 8px 23px;
          border-radius: 25px;
          margin-top: 50px;
          height: 45px;
          @include flexCenter; } } } } }
