// cors

$primary: #01215d;
$secondary: #00102e;
$colorText: #46474a;
$colorTextWhite: #fff;
$colorButton: #fe8923;

//-

// responsa
//-
$large-desktop: 1200px;


$medium-large: 1024px;
$medium-large-max: $large-desktop - 1;

$desktop: 992px;
$desktop-max: $large-desktop - 1;

$tablet: 768px;
$tablet-max: $desktop - 1;

$phablet: 576px;
$phablet-max: $tablet - 1;

$mobile: 400px;
$mobile-max: $phablet - 1;

$small-mobile: 321px;
$small-mobile-max: $mobile - 1;

$breakmenu: 859px;


// sizes
//-
$gutter: 20px;


// Effects
