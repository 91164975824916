.footer {
  @include below(desktop) {
    padding: 20px 0; }
  font-size: 14px;
  @include above(tablet) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .column-top {
    padding: 100px 15px 35px 15px;
    @include below(desktop) {
      padding: 20px 0; } }

  .column-bottom {
    padding: 35px 15px 35px 15px;
    @include below(desktop) {
      padding: 10px 0; } }

  .column {
    color: #46474a;
    @include above(tablet) {
      width: 33.3333%; } }

  .column-top,
  .column-bottom {
    &:nth-child(3n+2) {
      @include above(tablet) {
        border-left: 1px solid #f2f2f2;
        border-right: 1px solid #f2f2f2; } }

    &:nth-child(1) {
      @include above(tablet) {
        border-left: 1px solid #f2f2f2; } }

    &:nth-child(3) {
      @include above(tablet) {
        border-right: 1px solid #f2f2f2; } }

    .title {
      h4 {
        color: #01215d;
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.38px;
        margin-bottom: 5px; } }

    .info-tel {
      font-size: 20px;
      font-style: normal;
      letter-spacing: 0.5px;
      strong {
        font-weight: 700; }

      a {
        display: flex;
        + {
          a {
            margin-top: 10px; } }
        i {
          width: 25px;
          margin-right: 5px; } }

      .fa-whatsapp {
        width: 25px;
        height: 25px;
        background-color: #25d366;
        border-radius: 50%;
        @include flexCenter;
        font-size: 14px;
        color: #fff; } }

    .info-mail {
      a {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.38px;

        i {
          font-size: 17px;
          margin-right: 5px; } } }

    .info-end {
      display: flex;
      align-items: center;
      font-size: 15px;
      letter-spacing: 0.38px;
      i {
        font-size: 24px;
        margin-right: 10px; } }

    .cop,
    .nexos {
      color: #bfbfbf;
      @include above(tablet) {
        margin-top: 15px; } }

    .nexos {
      display: flex;
      align-items: center;
      a {
        margin-bottom: 5px;
        margin-left: 5px; } }

    .social-footer {
      display: flex;

      @include above(tablet) {
        justify-content: center; }
      a {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        @include flexCenter;
        color: #fff;
        font-size: 18px;
        margin: 0 3px;
        transition: all 0.3s ease;
        &:hover {
          box-shadow: 0 0 7px 0px #585858; }

        &.insta {
          background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%); }

        &.face {
          background: #3e5c9a; }

        &.whats {
          background: #25d366; } } } } }

.footer-bottom {
  position: relative;
  @include above(tablet) {
    &::before {
      content: '';
      height: 1px;
      background:  #f2f2f2;
      width: 90%;
      display: table;
      margin: 0 auto; } } }
