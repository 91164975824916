.lista-de-imoveis {
  padding-bottom: 80px;
  @include below(phablet) {
    padding-bottom: 40px; }
  .card-imovel {

    background: #fff;
    box-shadow: 0 0 20px 2px #e9e9e9;
    border-radius: 10px;
    margin-top: 60px;

    @include below(phablet) {
      margin-top: 30px; }

    @include above(phablet) {
      display: grid;
      grid-template-columns: 30% 70%; }

    .card-thumb {
      position: relative;
      height: 304px;

      @include below(phablet) {
        height: 190px; }

      a {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 10px 0 0 10px;
        background-position: center center;
        background-size: cover;

        @include below(phablet) {
          border-radius: 10px 10px 0 0; } } }

    .card-infos {
      padding: 30px;
      @include below(tablet) {
        padding: 15px; }
      .top {
        display: flex;
        justify-content: space-between;


        .nome-cond {
          max-width: 77%;
          min-width: 77%;
          h3 {
            color: #01215d;
            font-weight: 700;
            font-size: 2.7rem;

            @include below(tablet) {
              font-size: 2.4rem; } }

          p {
            color: #46474a;
            font-size: 14px;
            letter-spacing: 1.4px;
            font-weight: 400;
            text-transform: uppercase;
            display: block;
            margin-top: 5px; } } }

      .mid {
        margin-top: 35px;
        @include below(tablet) {
          margin-top: 15px; }
        .valor-obra {
          display: flex;
          justify-content: flex-end;
          color: #46474a;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 10px; }
        .barra-progress {
          position: relative;
          .line-gray {
            width: 100%;
            height: 5px;
            background: #cacaca; }

          .line-blue {
            position: absolute;
            top: 0;
            height: 5px;
            background: #01215d; } } }

      .bottom {
        margin-top: 50px;
        @include below(tablet) {
          margin-top: 30px; }

        a {
          padding: 15px 35px;
          font-size: 14px;
          @include below(phablet) {
              width: 100%;
              text-align: center; } } } } } }


// Single Obras Andamento
.single-obras-andamento {
  .rua {
    color: #46474a;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    @include below(tablet) {
      font-size: 14px;
      line-height: 22px; } }

  .etapas-obras {
    margin-top: 50px;
    color: #46474a;
    .text-1 {
      font-size: 40px;
      font-weight: 300;
      letter-spacing: 3.2px;

      @include below(tablet) {
        font-size: 3.6rem;
        line-height: 4.0rem;
        letter-spacing: 1.6px; } }

    .text-2 {
      color: #46474a;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px; } }

  .list-progress {
    display: grid;
    grid-gap: 15px 0;
    grid-template-columns: 1fr;
    margin-top: 20px;
    @include above(tablet) {
      grid-gap: 25px 50px;
      grid-template-columns: 2fr 2fr;
      margin-top: 40px; }
    .item {
      color: #46474a;
      .info-obra {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-transform: uppercase;
        margin-bottom: 5px;
        .nome-etapa {
          font-size: 15px;
          font-weight: 700;
          line-height: 24px; }

        .valor-obra {
          display: flex;
          justify-content: flex-end;
          color: #46474a;
          font-size: 16px;
          font-weight: 700; } }


      .barra-progress {
        position: relative;
        .line-gray {
          width: 100%;
          height: 5px;
          background: #cacaca; }

        .line-blue {
          position: absolute;
          top: 0;
          height: 5px;
          background: #01215d; } } } }

  .galeria-imagens {
    margin-top: 110px;
    @include below(phablet) {
      margin-top: 40px; } } }

// Lista Imovel SIngle
.archive-imovel-single {
  .heading {
    margin-top: 50px; }
  .icons-destaques {
    ul {
      display: flex;
      color: #00102e;
      font-size: 18px;
      font-weight: 400;
      grid-gap: 0 30px;
      align-items: flex-end;

      @include below(phablet) {
        font-size: 16px;
        grid-gap: 15px 10px;
        flex-wrap: wrap; }

      li {
        i {
          color: #00102e;
          margin-right: 5px;
          font-size: 28px;

          @include below(phablet) {
            font-size: 20px;
            display: table;
            margin: 0 auto;
            margin-bottom: 5px; } } } } }

  .bottom {
    display: flex;
    justify-content: space-between;
    @include below(phablet) {
      flex-direction: column;
      margin-top: 15px !important; }
    a {
      text-align: center;
      @include below(phablet) {
        margin-top: 10px; } } } }
.flag-imovel a,
.flag-imovel span {
  &.color-construcao {
    background: #df401f; }

  &.color-lancamento {
    background: #149238; }

  &.color-futuro {
    background: #0058a4; } }

.filterBurron-mobile {
  width: 100%;
  height: 41px;
  border-radius: 4px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.28px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  background-image: url('../images/arrow-down-white.png');
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-color: #01215d;
  i {
    margin-right: 10px;
    font-size: 18px; }
  @include above(phablet) {
    display: none; } }

.filter {
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr;
  grid-gap: 0 50px;

  @include below(tablet) {
    display: none; }

  &.active {
    display: block; }

  .item {
    @include below(tablet) {
      margin-top: 10px; }
    select {
      height: 41px;
      border-radius: 4px;
      border: 1px solid #bfbfbf;
      width: 100%;
      color: #46474a;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.28px;
      padding: 0 15px;
      text-transform: uppercase;
      appearance: none;
      background-image: url('../images/icon-arrow-down-select.png');
      background-repeat: no-repeat;
      background-position: center right 10px; }

    button {
      width: 100%;
      height: 41px;
      border-radius: 4px;
      background-color: #fe8923;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.28px;
      color: #fff;
      border: none; } } }

.archive-imovel-single {
  .card-infos {
    .icons-destaques {
      ul {
        li {
          display: flex;
          align-items: flex-end;
          img {
            filter: invert(81%) sepia(9%) saturate(1789%) hue-rotate(181deg) brightness(82%) contrast(91%);
            width: 35px;
            height: 35px;
            padding-top: 5px;
            margin-right: 5px;

            @include below(phablet) {
              width: 25px;
              height: 25px; } } } } } } }
