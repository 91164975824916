.images-sobre {
  margin-top: 70px;

  @include below(tablet) {
    margin-top: 20px; }

  .item {
    position: relative;
    + {
      .item {
        @include below(tablet) {
          margin-top: 25px; } } }

    &:nth-child(1) {
      &::before {
        content: '';
        width: 94%;
        height: 90%;
        border-radius: 6px;
        border: 1px solid #e3e3e3;
        position: absolute;
        right: 0;
        bottom: -20px;
        z-index: -1; } }

    &:nth-child(2) {
      &::before {
        content: '';
        width: 94%;
        height: 90%;
        border-radius: 6px;
        border: 1px solid #e3e3e3;
        position: absolute;
        left: -10px;
        top: -30px;
        z-index: -1; } } } }

.infos-sobre {
  .content-infos {
    background: #f6f7f8;
    padding: 25px 30px;
    margin-top: 70px;
    border-radius: 6px;

    @include below(tablet) {
      margin-top: 20px;
      text-align: center; }

    p {
      color: #46474a;
      font-size: 14px;
      font-weight: 300;
      line-height: 23px; }

    img {
      @include below(tablet) {
        margin-top: 10px; } } } }


.button-whatsapp__sobre {
  display: table;
  margin: 0 auto;
  margin-top: 50px;
  a {
    width: 206px;
    height: 55px;
    border-radius: 8px;
    background-color: #25d366;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    @include flexCenter;

    i {
      font-size: 24px;
      margin-right: 8px; } } }

.obras-realizadas {
  margin-top: 70px; }


.timeline {
  position: relative;
  margin-top: 60px;
  padding-bottom: 60px;

  @include below(large-desktop) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    padding-bottom: 20px; }

  &::before {
    content: '';
    height: 100%;
    width: 12px;
    background: #bfbfbf;
    position: absolute;
    left: 165px;
    z-index: -1;
    @include below(large-desktop) {
      display: none; } }

  .circle {
    width: 26px;
    height: 26px;
    background-color: #bfbfbf;
    position: absolute;
    left: 158px;
    border-radius: 50%;
    @include below(large-desktop) {
      display: none; }
    &.circle-top {
      top: -5px; }

    &.circle-bottom {
      bottom: -5px; } }

  .item {
    display: flex;
    align-items: center;

    @include below(large-desktop) {
      flex-direction: column;
      width: 48%;
      margin-top: 45px; }

    @include below(tablet) {
      width: 100%;
      padding: 0 20px; }
    + {
      .item {
        margin-top: 80px;
        @include below(large-desktop) {
          margin-top: 45px;

          &:last-child {
            .text {
              &::before {
                display: none; } } } } } }

    .numero {
      color: #84c2ff;
      font-size: 80px;
      font-weight: 700;
      letter-spacing: -5.95px;
      border: 7px solid #eaeaea;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      line-height: 95px;
      padding-top: 5px;
      padding-right: 5px;

      @include below(desktop) {
        font-size: 60px;
        line-height: 65px;
        padding-right: 0;
        width: 90px;
        height: 90px;
        letter-spacing: -1.50px; } }

    .bullet {
      width: 42px;
      height: 42px;
      border: 10px solid #ffffff;
      background-color: #84c2ff;
      border-radius: 50%;
      margin-left: 30px;
      @include below(large-desktop) {
        display: none; } }

    .icon {
      margin-left: 30px;
      @include below(large-desktop) {
        display: none; } }

    .title {
      width: 290px;
      padding-left: 20px;
      border-bottom: 1px dashed #46474a;
      margin-left: -10px;

      @include below(large-desktop) {
        margin-left: 0;
        width: auto;
        padding-left: 0;
        margin-bottom: 15px;
        margin-top: 15px; }

      p {
        color: #01215d;
        font-size: 25px;
        font-weight: 400;
        letter-spacing: -0.5px;


        padding-bottom: 7px;
        text-transform: uppercase;
        float: right; } }

    .text {
      margin-left: 45px;
      width: 390px;
      padding: 30px 15px 30px 20px;
      background: #eaeaea;
      color: #46474a;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      position: relative;

      @include below(desktop) {
        width: auto;
        margin-left: 0; }

      &::before {
        content: '';
        width: 42px;
        height: 42px;
        position: absolute;
        background: #eaeaea;
        left: -15px;
        transform: translateY(-50%) rotate(45deg);
        top: 50%;
        z-index: -1;
        @include below(desktop) {
          top: auto;
          bottom: -35px;
          left: 43%; } }

      p {
        position: relative;
        z-index: 999; }

      span {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px solid #84c2ff;
        left: 5px;
        top: -5px;
        @include below(tablet) {
          display: none; }
        &::before {
          content: '';
          width: 32px;
          height: 32px;
          background: #eaeaea;
          left: -15px;
          transform: translateY(-50%) rotate(45deg);
          top: 50%;
          z-index: -1;
          display: inline-block;
          margin-top: 70px;
          border-left: 2px solid #84c2ff;
          margin-left: -17px;
          border-bottom: 2px solid #84c2ff; } } } } }

.section-1-sobre {

  .button-comecar {
    @include below(large-desktop) {
      display: table;
      margin: 0 auto;
      margin-top: 20px; }
    @include above(desktop) {
      margin-left: 210px; }
    a {
      width: 245px;
      height: 60px;
      border-radius: 10px;
      background-color: #0058a4;
      color: #ffffff;
      font-size: 25px;
      font-weight: 300;
      font-style: normal;
      letter-spacing: -0.75px;
      @include flexCenter;
      transition: all 0.3s ease;

      @include below(tablet) {
        width: 180px;
        height: 50px;
        font-size: 20px; }

      i {
        margin-left: 20px;
        transition: all 0.3s ease; }

      &:hover {
        background: #034a87;
        i {
          margin-left: 25px; } } } } }
