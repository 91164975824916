.hero-single {

  @include below(tablet) {
    height: auto;
    padding-bottom: 40px; }

  .container {
    position: relative; }

  .logo-cod {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 160px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    @include below(tablet) {
      position: relative;
      display: flex;
      margin-top: 30px;
      width: 90px;
      height: 90px; }

    img {
      @include below(tablet) {
        width: 80%; } } }

  .content-hero {
    position: relative;
    z-index: 999;
    padding-top: 120px;

    @include below(tablet) {
      padding-top: 0; }


    .top {
      .bairro {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        color: #84c2ff; }

      .nome-cond {
        color: #f6f7f8;
        font-size: 4.0rem;
        font-weight: 700;
        line-height: 4.5rem;

        @include below(tablet) {
          font-size: 3.6rem;
          line-height: 4.0rem; } } }

    .mid {
      margin-top: 30px;
      .lista-itensImovel {
        ul {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          @include below(tablet) {
            grid-gap: 20px; }
          li {
            color: #f6f7f8;
            font-size: 21px;
            font-weight: 400;
            display: flex;
            align-items: center;
            @include below(tablet) {
              font-size: 18px; }

            + {
              li {
                margin-left: 40px;
                @include below(tablet) {
                  margin-left: 0; } } }


            img {
              width: 50px;
              margin-right: 10px;
              height: 50px;

              @include below(tablet) {
                width: 35px;
                height: 35px; } } } } } }

    .bottom {
      margin-top: 30px;
      .text {
        p {
          color: #f6f7f8;
          font-size: 25px;
          font-weight: 700; } }
      .link {
        margin-top: 10px;
        a {
          width: 225px;
          height: 50px;
          border-radius: 10px;
          background-color: #84c2ff;
          font-size: 14px;
          letter-spacing: 0.28px;
          font-weight: 700;
          @include flexCenter;
          color: #fff;
          transition: all 0.3s ease;

          &:hover {
            background: #2b8ceb; }

          i {
            font-size: 28px;
            margin-right: 10px; } } } } } }

.column-single {
  margin-top: 40px; }

.mid-single-imovel {

  .title-2 {
    margin-bottom: 5px; }

  .preset-text1 {
    p {
      + {
        p {
          margin-top: 15px; } } } }

  .detalhes-imovel {
    ul {
      display: flex;
      grid-gap: 0 5px;
      flex-wrap: wrap;
      @include below(tablet);

      li {
        color: #46474a;
        font-size: 14px;
        font-weight: 300;
        line-height: 17px;
        margin-top: 20px;
        width: 120px;
        text-align: center;

        @include below(tablet) {
          width: 24%; }

        span {
          display: flex;
          width: auto;
          height: 50px;
          img {
            margin: 0 auto;
            display: table;
            margin-bottom: 10px; } } } } }

  .anchor-links {
    display: flex;
    grid-gap: 15px 30px;
    margin-top: 30px;
    flex-wrap: wrap;
    @include below(desktop) {
      grid-gap: 15px 10px; }
    a {

      color: #46474a;
      font-size: 14px;
      font-weight: 700;
      height: 51px;
      padding: 0 30px;
      background: #eaeaea;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      transition: all 0.3s ease;

      @include below(desktop) {
        width: 48%; }

      @include below(phablet) {
        width: 100%; }

      &:hover {
        background: $colorButton;
        color: #fff; }

      i {
        margin-left: 5px; }

      &.corretor {
        i {
          font-size: 18px;
          margin-left: 10px; }

        &:hover {
          background: #d76807 !important; } } } } }


.galeria-de-imagens {
  margin-top: 30px;
  position: relative;
  .carousel-imagens {

    .item {
      position: relative;
      height: 501px;
      border-radius: 25px;
      cursor: move;

      @include below(tablet) {
        height: 270px;
        border-radius: 15px; }

      .image {
        position: absolute;
        left: 0;
        top: 0;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        @include below(tablet) {
          border-radius: 15px; } } }

    .slick-dots {
      margin: 0 auto;
      display: table;
      margin-top: 10px;
      li {
        display: inline-block;
        margin: 0 5px;

        button {
          font-size: 0px !important;
          border-radius: 50%;
          width: 14px;
          height: 14px;
          border: 1px solid #acacac;
          background: none; }

        &.slick-active {
          button {
            background: #acacac; } } } } }

  .slick-arrows {
    position: absolute;
    transform: translateY(-50%);
    color: #000000;
    font-size: 40px;
    top: 45%;
    width: 100%;
    cursor: pointer;

    @include below(desktop) {
      display: none; }

    .arrow-prev {
      left: -35px;
      position: absolute; }

    .arrow-next {
      right: -35px;
      position: absolute; } } }


.mapa {

  .mapIframe {
    height: 392px;
    position: relative;
    width: 100%;
    margin-top: 30px;

    @include below(tablet) {
      height: 250px; }

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; } } }


.contato-corretor {
  background: #69a8d3;
  padding: 50px 0;

  .container {
    @include above(phablet) {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .text p {
      color: #ffffff;
      font-size: 3.0rem;
      font-weight: 700;
      letter-spacing: -0.3px;
      line-height: 32px;
      @include below(tablet) {
        text-align: center; } }


    .buttons {
      @include above(phablet) {
        display: flex;
        min-width: 60%; }

      @include below(tablet) {
        margin: 0 auto;
        display: table;
        margin-top: 15px; }

      a {
        width: 218px;
        height: 51px;
        border-radius: 10px;
        color: #f6f7f8;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        + {
          a {
            @include above(phablet) {
              margin-left: 30px; }

            @include below(tablet) {
              margin-top: 10px; } } }

        i {
          margin-left: 7px;
          font-size: 14px; }
        &:nth-child(1) {
          background-color: #fe8923; }
        &:nth-child(2) {
          background-color: #25d366; } } } } }
