
.banner {
  position: relative;
  .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    li {
      display: inline-block;
      margin: 0 5px;

      button {
        font-size: 0px !important;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        border: 1px solid #133479;
        background: none; }

      &.slick-active {
        button {
          background: #133479; } } } } }

.box-imoveis-vertical {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-gap: 40px 6%;

  @include below(large-desktop) {
    grid-gap: 40px 2%; }


  @include below(desktop) {
    grid-template-columns: 2fr 2fr;
    grid-gap: 15px 3%; }

  @include below(phablet) {
    grid-template-columns: 100%; }

  .item {
    height: 565px;
    display: flex;
    align-items: flex-end;
    padding: 20px;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    .thumb-card {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all 0.4s ease;

      &::before {
        content: '';
        position: absolute;
        background: rgba(0,0,0,0.4);
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 10px; } }

    @include below(desktop) {
      height: 400px; }

    &:hover {
      .thumb-card {
        transform: scale(1.15); } }

    .flag-imovel {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 99;
      span {
        padding: 0 18px;
        height: 46px;
        border-radius: 8px;
        color: #ffffff;
        font-size: 20px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 1px;
        @include flexCenter;
        text-transform: uppercase;

        @include below(phablet) {
          font-size: 16px; } } }


    .conteudo-imovel {
      position: relative;
      z-index: 99;
      width: 100%;
      @include below(phablet) {
        width: 95%; }
      &:after {
        content: '';
        width: 76px;
        height: 1px;
        border: 1px solid #f6f7f8;
        opacity: 0.8;
        display: table;
        margin: 0 auto;
        margin-top: 15px; }
      .cidade {
        font-size: 14px;
        letter-spacing: 0.28px;
        font-weight: 700;
        color: #fff; }

      .bairro {
        font-weight: 700;
        font-size: 30px;
        letter-spacing: 0.6px;
        color: #fff;

        @include below(tablet) {
          font-size: 24px; } }

      .nome-cond {
        font-size: 25px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: 0.75px;
        color: #84c2ff;
        text-transform: uppercase;
        @include below(tablet) {
          font-size: 18px; } }

      .lista-itensImovel {
        display: flex;
        font-size: 15px;
        letter-spacing: -0.3px;
        justify-content: space-between;
        margin-top: 10px;
        ul {
          display: grid;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          grid-template-columns: 3fr 3fr 3fr;
          align-items: flex-end;
          text-align: center;
          li {
            color: #fff;
            padding: 5px 3px;

            img {
              width: 25px;
              height: 25px;
              display: block;
              text-align: center;
              margin: 0 auto; }

            i {
              font-size: 20px; } } } }

      .link-imovel {
        a {
          @include flexCenter;
          width: 100%;
          height: 41px;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          letter-spacing: 0.48px;
          text-transform: uppercase;
          margin-top: 8px;
          transition: all 0.3s ease;
          &:hover {
            background: #7fcd02 !important; } } } } } }

.section-1 {
  .button-1 {
    margin: 0 auto;
    display: table;
    margin-top: 60px; } }

.section-2 {

  @include above(desktop) {
    padding: 0 50px; }

  .container {
    position: relative; }

  .image-family {
    position: absolute;
    right: calc(50% - -75px);
    bottom: 0;
    width: 700px;

    @include below(desktop) {
      display: none; } }

  .content {
    padding-top: 45px;
    background: #f6f7f8;
    display: inline-block;
    width: 100%;
    position: relative; }


  .title-1 {

    h2 {
      text-align: right;
      @include below(tablet) {
        text-align: center; } } }

  .form-home {
    margin-top: 50px;
    @include below(tablet) {
      margin-top: 10px; }
    input[type=text],
    input[type=email] {
      height: 62px;
      border-radius: 10px;
      border: 1px solid #bfbfbf;
      background-color: #f6f7f8;
      width: 100%;
      padding: 0 25px;
      font-size: 14px;
      font-weight: 300; }

    input[type=submit] {
      width: 100%;
      height: 62px;
      border-radius: 10px;
      background-color: #01215d;
      border: none;
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      background-image: url('../images/icon-arrow-button-submit-form-home.svg');
      background-repeat: no-repeat;
      background-position: right 60px center;
      padding-right: 40px;

      @include below(tablet) {
        background-position: right 20px center; } } }

  .banners-simulacao {
    margin-top: 25px;
    margin-bottom: -90px;

    @include below(tablet) {
      margin-top: 20px;
      margin-bottom: 40px; } } }


.section-3 {
  margin-top: 120px;
  margin-bottom: 120px;
  @include below(phablet) {
    margin-top: 40px;
    margin-bottom: 40px; }
  .sub-text {
    color: #46474a;
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 2.5px; }

  .right {
    @include below(tablet) {
      margin-top: 35px; } }

  .inner-section {
    @include above(phablet) {
      display: grid;
      grid-template-columns: 41% 49%;
      grid-gap: 30px 10%; } }

  .content-video {
    position: relative;
    padding-bottom: 50px;
    padding-left: 50px;
    @include below(phablet) {
      padding-left: 20px;
      padding-bottom: 5px; }
    &::before {
      content: '';
      width: 92%;
      height: 85%;
      border-radius: 6px;
      border: 10px solid #01215d;
      position: absolute;
      z-index: -1;
      left: 5px;
      top: 40px;

      @include below(phablet); }


    .video {
      position: relative;
      padding-bottom: 56.25%;
      overflow: hidden;

      iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 6px; } } }

  .dezAnos {

    margin-top: 20px;
    position: relative;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    @include above(phablet) {
      float: right; }
    &:after {
      content: '';
      width: 10px;
      height: 100%;
      background: #01215d;
      position: absolute;
      right: 0;
      top: 0; }


    .text-1,
    .text-3 {
      color: #46474a;
      font-size: 25px;
      font-weight: 300; }

    .text-2 {
      color: #46474a;
      font-size: 80px;
      font-weight: 700;
      letter-spacing: -4px;
      line-height: 60px; }

    .text-3 {
      text-align: right; } } }


.section-5 {
  .content-1 {
    padding-top: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: -80px;
    background-position: center center;

    @include below(tablet) {
      margin-bottom: 0;
      padding-bottom: 40px;
      padding-top: 40px; } }

  .inner-section {


    @include above(phablet) {
      display: flex;
      justify-content: space-between; }


    .video {
      @include below(tablet) {
        text-align: center;
        margin-bottom: 30px; }
      .playvideo {
        @include flexCenter;
        width: 100px;
        height: 100px;
        background-color: #0058a4;
        color: #fff;
        font-size: 36px;
        border-radius: 50%;
        margin-top: 20px;
        cursor: pointer;
        @include below(tablet) {
          margin: 0 auto; }
        i {
          margin-left: 10px; } }

      .text {
        color: #f6f7f8;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 2.4px;
        margin-top: 15px; } }

    .form {
      width: 446px;
      border-radius: 10px;
      padding: 30px 25px;
      background: #fff;
      box-shadow: 0 7px 14px 3px #b1b1b17a;

      @include below(tablet) {
        margin: 0 auto;
        display: table; }

      @include below(phablet) {
        width: 100%; }

      p {
        color: #46474a;
        font-size: 25px;
        font-weight: 400;
        margin-bottom: 25px; }
      form {
        .item {
          margin-bottom: 10px; }
        input[type=text] {
          width: 100%;
          height: 52px;
          border-radius: 10px;
          border: 1px solid #bfbfbf;
          padding: 0 20px;
          color: #46474a;
          font-size: 14px;
          font-weight: 300;

          &::placeholder {
            color: #46474a;
            font-size: 13px;
            font-weight: 300; } }

        textarea {
          width: 100%;
          height: 111px;
          border-radius: 10px;
          border: 1px solid #bfbfbf;
          padding: 20px;
          color: #46474a;
          font-size: 14px;
          font-weight: 300;
          max-height: 111px;
          max-width: 100%;
          min-height: 111px;
          min-width: 100%;

          &::placeholder {
            color: #46474a;
            font-size: 13px;
            font-weight: 300; } }

        input[type=submit] {
          width: 195px;
          height: 52px;
          border-radius: 10px;
          background-color: #fe8923;
          color: #f6f7f8;
          font-size: 15px;
          font-weight: 700;
          letter-spacing: 1.5px;
          @include flexCenter;
          border: none;
          float: right; } } } }

  .content-2 {
    background: #f6f7f8;
    padding: 35px 0;

    img:nth-child(2) {
      margin-right: 130px;

      @include below(large-desktop) {
        margin-right: 450px; }

      @include below(tablet) {
        margin-right: 0;
        margin-left: 0;
        display: table; } } } }
