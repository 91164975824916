.button-nav {
	display: none;
	z-index: 999;

	@include below(desktop) {
		display: block; }

	.btn-burguer .icon-bar:nth-of-type(2) {
		top: 1px; }

	.btn-burguer .icon-bar:nth-of-type(3) {
		top: 2px; }

	.btn-burguer .icon-bar {
		position: relative;
		transition: all 0.3s; }

	.btn-burguer.active .icon-bar:nth-of-type(1) {
		top: 10px;
		transform: rotate(45deg);
		background: $primary; }

	.btn-burguer.active .icon-bar:nth-of-type(2) {
		background-color: transparent; }

	.btn-burguer.active .icon-bar:nth-of-type(3) {
		top: -4px;
		transform: rotate(-45deg);
		background: $primary; }

	.btn-burguer {
		height: 40px;
		z-index: 9999;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		flex-direction: column;
		cursor: pointer;
		float: left;
		z-index: 999999;
		position: relative;

		@include below(tablet) {
			margin-left: 30px; } }


	.btn-burguer .icon-bar {
		background: $primary;
		display: table;
		margin: 0 auto;
		margin-bottom: 5px;
		width: 26px;
		height: 2px; } }
