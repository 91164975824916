.section-1-contato {
  background: #f6f7f8;
  overflow-x: hidden;
  .content-text {
    padding: 120px 0;
    @include below(desktop) {
      padding: 40px 0; }

    .tels {
      display: flex;
      @include below(phablet) {
        flex-direction: column-reverse; }
      .item {
        display: flex;
        align-items: center;
        @include below(phablet);


        + {
          .item {
            margin-left: 20px;
            @include below(phablet) {
              margin-left: 0;
              margin-bottom: 20px; } } }


        .text {
          margin-left: 10px;
          p {
            color: #46474a;
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 0.33px; }

          a {
            font-size: 25px;
            color: #46474a;
            letter-spacing: 0.63px; } }

        i {
          width: 59px;
          height: 59px;
          font-size: 32px;
          border-radius: 50%;
          @include flexCenter;
          color: #fff;
          box-shadow: 0 0 7px 0px #707070;

          @include below(phablet) {
            width: 45px;
            height: 45px; } }

        .fa-whatsapp {
          background-color: #25d366;
          @include below(phablet) {
            font-size: 24px; } }

        .fa-phone-alt {
          background: #fe8923;
          font-size: 25px;
          @include below(phablet) {
            font-size: 18px; } } } } }

  .image {
    position: relative;
    margin-right: -100%;
    height: 100%;
    @include below(desktop) {
      display: none; }
    img {
      object-fit: cover;
      object-position: center center;
      height: 100%; } } }


.section-2-contato {
  padding: 70px 0;
  background: #01215d;

  @include below(desktop) {
    padding: 40px 0; }

  .text-1 {
    color: #f6f7f8;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.32px;
    line-height: 25px; }

  form {
    margin-top: 40px;
    .item {
      margin-bottom: 20px; }

    input[type=text],
    input[type=email] {
      width: 100%;
      height: 52px;
      border-radius: 4px;
      border: 1px solid #bfbfbf;
      padding: 0 20px;
      color: #46474a;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;

      &::placeholder {
        color: #46474a;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 1.3px; } }

    input[type=submit] {
      width: 217px;
      height: 49px;
      border-radius: 4px;
      background-color: #fe8923;
      color: #ffffff;
      font-size: 17px;
      font-weight: 700;
      @include flexCenter;
      border: none; }

    textarea {
      width: 100%;
      height: 139px;
      border-radius: 4px;
      background-color: #ffffff;
      padding: 20px;
      color: #46474a;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      &::placeholder {
        color: #46474a;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 1.3px; } } }


  .image-form {
    margin-top: 90px;

    @include below(desktop) {
      margin-top: 40px; } } }
