
.color-blue {
  color: $primary !important; }

.color-gray {
  color: $colorText !important; }

.color-white {
  color: #fff !important; }

.background-greenLight {
  background: #94ea0a !important; }

.background-orange {
  background: #fe8923 !important; }


.space-padding {
  padding: 70px 0;
  @include below(tablet) {
    padding: 40px 0; } }

.title-1 {
  h2 {
    color: #46474a;
    font-size: 4.0rem;
    font-weight: 700;
    line-height: 4.0rem;
    font-family: $fontCondensed;

    @include below(tablet) {
      font-size: 3.2rem;
      line-height: 3.8rem; } } }


.title-2 {
  h3 {
    color: #0058a4;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px; } }

.button-1 {
  input[type=submit],
  button,
  a {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    color: #f6f7f8;
    padding: 20px 65px;
    border-radius: 10px;
    background-color: #fe8923;
    text-transform: uppercase;
    display: inline-block;
    transition: all 0.3s ease;
    &:hover {
      background: #d76807; } } }



.preset-text1 {
  p {
    color: #46474a;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;

    + {
      p {
        margin-top: 20px; } } } }

.hero-pages {
  height: 467px;
  background-size: cover;
  background-position: center center;
  position: relative;

  @include below(desktop) {
    padding: 50px 0;
    height: auto; }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    top: 0;
    left: 0; }

  .title-hero {
    margin-top: 100px;
    display: inline-block;
    position: relative;
    @include below(desktop) {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
    h2 {
      color: #8b8580;
      font-size: 82px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 5px;
      text-transform: uppercase;
      position: relative;
      color: black;

      @include above(tablet) {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #4f71a9; }

      @include below(desktop) {
        color: #fff;
        font-size: 4.2rem;
        text-align: center;
        margin-top: 0; } }

    span {
      position: absolute;
      color: #f6f7f8;
      font-size: 60px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: 3.8px;
      left: 20px;
      top: 30px;
      text-transform: uppercase;
      @include below(desktop) {
        display: none; } } } }
.heading {
  p {
    text-transform: uppercase;
    color: #0058a4;
    font-size: 20px;
    font-weight: 300; } }


.title-ap {
  position: relative;
  h2 {
    background: #fff;
    padding-right: 20px;
    position: relative;
    z-index: 9;
    display: inline-block; }
  &::before {
    content: '';
    height: 1px;
    background: #e3e3e3;
    position: absolute;
    width: 100%;
    bottom: 10px; } }

.br-hiddenMobilePhablet {
  @include below(phablet) {
    display: none; } }

.font-weight_light {
  font-weight: 300 !important; }

.font-weight_normal {
  font-weight: 400 !important; }

.font-weight_semibold {
  font-weight: 600 !important; }
